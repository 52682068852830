@import "variable";

.brand-view-module {
    .top-blk {
        .back-btn {
            img {
                margin-right: 3px;
                position: relative;
                top: 1px;
            }

            a {
                cursor: pointer;
            }
        }

        .button-wrapper {
            a {
                float: right;
            }
        }
    }

    .brand-view-container {
        .brand-details {
            .brand-heading {
                margin-bottom: 30px;

                .title {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .heading1 {
                        position: relative;
                        max-width: 78%;
                        font-size: 22px;
                        line-height: 26px;
                        text-align: center;

                        span {
                            display: inline-block;
                        }

                        span,
                        img {
                            vertical-align: middle;
                            word-break: break-word;
                        }

                        img {
                            position: relative !important;
                            top: -1.5px !important;
                            right: -5px !important;
                        }
                    }
                    .brand-name-btns {
                        position: absolute;
                        height: 100%;
                        left: 100%;
                        top: 0;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-size: 18px;
                        gap: 10px;
                        margin-left: 4px;

                        .edit-btn {
                            cursor: pointer;

                            img,
                            span {
                                vertical-align: middle;
                            }

                            span {
                                margin-left: 5px;
                                color: $color_0091B3;
                            }
                        }
                    }

                }
                .brand-action-btn {
                    background: $color_red;
                    border-radius: 200px;
                    color: #FFFFFF;
                    border: none;
                    padding: 17px 20px;
                    line-height: 4px;
                    cursor: pointer;

                    span {
                        background-color: $color_white;
                        display: inline-block;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        margin: 0 2px;
                    }

                    &.open {
                        background: $color_green;
                    }

                    &.disabled {
                        background-color: $color_7D858C;
                        cursor: auto;
                    }
                }

            }

            .brand-info {

                // text-align: center;
                img {
                    cursor: pointer;

                    &.edit-icon {
                        position: relative;
                        top: 4px;
                    }

                    &.brand-relationship-icon {
                        vertical-align: middle;
                        background: #00797C;
                        padding: 5px;
                        border-radius: 50%;
                        width: 25px;
                        height: 25px;
                        box-sizing: border-box;
                        margin-right: 2px;
                    }
                }

                .trustScore {
                    color: #00797C;
                    font-weight: $mediumFont !important;
                    vertical-align: middle;
                }

                .heading2 {
                    span {
                        font-weight: $regularFont;
                        margin-right: 5px;
                    }

                    font-weight: $boldFont;

                    &.tooltip {
                        img {
                            position: relative !important;
                            right: -5px !important;
                            top: 2px !important;
                        }
                    }
                }

                .left-blk,
                .right-blk,
                .middle-blk {
                    margin: 0 auto;
                    width: 80%;

                    h3 {
                        margin-bottom: 16px;

                        &:last-child {
                            margin: 0;
                        }
                    }
                }

                .middle-blk {
                    width: 100%;
                    padding-left: 10%;
                    padding-right: 10%;
                    box-sizing: border-box;
                    border: 1px solid rgba(73, 83, 93, 0.1);
                    border-top: none;
                    border-bottom: none;
                }

                h3 {
                    &.identity-status {
                        padding-right: 30px;
                        display: flex;
                        align-items: center;

                        .view-icon {
                            margin: 0 0 0 5px;
                            padding: 2px 3px;
                            border-radius: 50%;
                            cursor: pointer;

                            &:hover {
                                background: rgba(0, 145, 179, 0.1);
                            }
                        }
                    }
                }

                .right-blk {
                    padding-left: 10%;
                    width: 100%;
                    box-sizing: border-box;
                }
            }

            &.basic {
                margin-bottom: 30px;
                margin-top: 16px;
                position: relative;

                .float-right {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    gap: 12px;
                    position: absolute;
                    right: 0;
                }
            }

            &.more {
                margin-bottom: 20px;

                // padding: 0 70px;
                .details-heading {
                    padding-bottom: 8px;
                    border-bottom: 1px solid #C0CCD4;
                    margin-bottom: 12px;
                    position: relative;

                    .title {
                        position: relative;
                        display: inline-block;

                        h3 {
                            line-height: 21px;
                        }

                        img {
                            position: absolute;
                            top: -2px;
                            width: 25px;
                            height: 25px;
                        }
                    }

                    &.contact-details {
                        margin-top: 30px;
                    }

                    .edit-btn {
                        cursor: pointer;
                        display: inline-block;
                        margin-left: 16px;
                        position: relative;
                        top: -3px;

                        img {
                            position: static;
                            vertical-align: middle;
                            margin-right: 5px;
                        }

                        span {
                            color: $color_0091B3;
                            vertical-align: middle;
                        }
                    }

                    .brand-revet-btn {
                        position: absolute;
                        right: 0;
                        top: -10px;

                        a {
                            display: inline-block;
                            border-radius: 3px;
                            padding: 10px 16px;
                        }
                    }

                    &.campaigns-listing {
                        margin-bottom: 0;

                        .title {
                            position: absolute;
                            left: 0;
                            top: 0;
                        }

                        .filter-section {
                            .filter-button {
                                float: right;

                                img {
                                    top: unset;
                                    left: 15px;
                                }
                            }
                        }
                    }
                }
            }

            .details {
                border: 1px solid #C0CCD4;
                padding: 25px 30px;
                margin-bottom: 30px;

                .detail-item {
                    h3 {
                        word-break: break-word;

                        span {
                            font-weight: $mediumFont
                        }
                    }
                }

                &.vetting {
                    .details-heading {
                        padding-bottom: 0;
                        border-color: transparent;
                    }

                    ul.vetting {
                        position: absolute;
                        right: 0px;
                        top: -10px;

                        li {
                            cursor: pointer;
                            padding: 9px 8px;
                            background: $color_red;
                            margin-right: 15px;
                            border-radius: 3px;
                            border: 1px solid $color_red;
                            max-width: 120px;
                            text-align: center;

                            p {
                                color: #fff;
                                line-height: 14px;
                            }

                            &:last-child {
                                margin-right: 0;
                                background: #fff;

                                p {
                                    color: $color_red;
                                }
                            }
                        }
                    }

                    .brand-vetting-details {
                        .vetting-list {
                            width: 100%;
                            tbody tr td {
                                height: 40px;
                                padding-top: 0;
                                padding-bottom: 0;
                            }

                            .gridItem {
                                width: 18%;

                                &.outcome,
                                &.date {
                                    width: 18%;

                                    .inline-flex {
                                        display: inline-flex;
                                        align-items: center;
                                        img {
                                            margin-right: 5px;
                                        }
                                    }
                                }

                                &.id {
                                    width: 18%;
                                }

                                &.outcome {
                                    width: 21%;

                                    img {
                                        vertical-align: text-bottom;
                                        margin-right: 3px;
                                    }

                                    .view-icon {
                                        margin-left: 5px;
                                        display: inline-block;
                                        border-radius: 50%;
                                        height: 20px;
                                        width: 20px;
                                        background: transparent;
                                        position: relative;

                                        img {
                                            width: 18px;
                                            height: auto;
                                            left: 1px;
                                            top: 3px;
                                            cursor: pointer;
                                        }

                                        &.active {
                                            background: rgba(0, 145, 179, 0.1);
                                        }
                                    }

                                    .MuiTooltip-popper {
                                        pointer-events: auto !important;

                                    }
                                }

                                &.action {
                                    width: 5%;
                                }
                            }

                            .empty-row {
                                height: 150px;
                            }
                        }
                    }
                }

                &.campaigns-listing {
                    padding-bottom: 20px;
                    margin-bottom: 0;

                    .MuiAutocomplete-root {
                        width: unset;
                    }

                    .details-heading {
                        height: 21px;
                        padding-bottom: 0;
                        margin-bottom: 20px;
                        border-color: transparent;
                    }

                    .pagination-wrapper {
                        margin-top: 10px;

                        .pagination {
                            margin-top: 0;
                            padding-top: 20px;
                            margin: 0 -30px;
                            border-top: 1px solid #C0CCD4;
                        }
                    }
                }

            }
        }
    }
}

.brand-vetting-modal {
    left: 200px !important;

    .modal-wrapper {
        padding: 30px;
        width: 500px;
        box-sizing: border-box;

        .modal-content {
            .modal-info {
                margin-bottom: 20px;
                text-align: center;

                h3 {
                    margin-bottom: 20px;
                    color: #49535D;
                }

                p {
                    margin: 0 30px;
                    color: #49535D;
                }
            }

            form {
                .vetting-details {
                    margin-top: 30px;
                    margin-bottom: 15px;

                    h3 {
                        color: #00797C;

                        span {
                            font-weight: 500
                        }
                    }
                }

                .vetting-applied-wrapper {
                    margin-top: 30px;
                    text-align: center;

                    h3 {
                        &.confirm {
                            margin-bottom: 0;
                            color: #49535D
                        }
                    }
                }
            }

            .modal-footer {
                margin-top: 30px;

                ul {
                    li {
                        .form-group-field {
                            margin-bottom: 0;
                        }

                        &:first-child {
                            margin-right: 25px;
                        }
                    }
                }
            }

            .vetting-applied-wrapper {
                margin-bottom: 0;

                .vetting-partner {
                    margin-bottom: 30px;

                    p {
                        margin-bottom: 0;
                        font-weight: 500;
                        margin-top: 15px;
                        color: #49535D;

                        span {
                            font-weight: 400;
                            display: inline-block;
                            margin-left: 5px;
                            margin-top: 5px;
                            color: inherit;
                            word-break: break-word;
                        }

                        &:first-child {
                            margin-top: 10px;
                        }
                    }
                }

                img {
                    margin-bottom: 15px;
                }

                h3 {
                    &.confirm {
                        font-size: 14px;
                        margin-bottom: 30px;
                        color: #49535D;

                        span {
                            font-weight: 500;
                        }
                    }

                    &.pending {
                        line-height: 22px;
                    }
                }

                .form-group-field {
                    input[type="submit"] {
                        max-width: 150px;
                    }
                }
            }

            .vetting-loader-wrapper {
                text-align: center;
                min-width: 420px;

                .paragraph {
                    color: #49535D;
                    line-height: 22px;
                }

                a {
                    display: inline-block;
                    margin-top: 30px;
                }

                .progress-bar {
                    height: 60px;
                    width: 60px;
                    margin-bottom: 15px;

                    .CircularProgressbar-text {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.edit-modal {
    left: 200px !important;

    .edit-content {
        padding: 30px !important;

        .title {
            position: relative;
            margin-bottom: 6px;

            .heading1 {
                // font-size: 18px;
                // margin-left: 35px;
            }

            img {
                position: absolute;
                left: 0px;
                top: -5px;
                width: 30px;
            }

            &.contact {
                margin-top: 40px;

                img {
                    width: 24px;
                    top: -3px;
                }

                .heading1 {
                    // margin-left: 29px;
                }
            }

            &.brandName {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .heading1 {
                    // margin-left: 6px;
                }

                img {
                    position: static;
                    width: 24px;
                }
            }

            &.brandRelationship {
                margin-bottom: 0;
                max-width: 165px;

                img {
                    &.icon {
                        width: 25px;
                        left: 0 !important;
                    }

                    left: 170px !important;
                }

                .heading1 {
                    // margin-left: 30px;
                }
            }
        }

        form {
            width: 100%;
            margin-top: 13px;

            .form-group-field {
                margin-bottom: 0;
            }
        }

        .footer {
            padding-top: 30px;
            text-align: center;

            li {
                display: inline-block;
                margin-right: 25px;

                a {
                    display: inline-block;
                    box-sizing: border-box;
                    min-width: 80px;
                }

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}

.feedback-list-tooltip {
    max-width: 388px;
    border: 1px solid $color_green;

    li {
        padding: 12px 20px;

        &:nth-child(even) {
            background: rgba(255, 255, 255, 0.4);
        }
    }
}

.vetting-details-tooltip {
    .title {
        border-radius: 3px 3px 0 0;
        font-weight: $boldFont;
        background-color: $color_0091B3;
        padding: 10px 20px;
        color: white;
        font-size: $font16 !important;
        margin-bottom: 0;
        text-align: left;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        font-family: Roboto, sans-serif;
    }

    ul {
        padding: 20px 0;

        li {
            margin-bottom: 16px;
            padding: 0 20px;

            span {
                font-weight: $boldFont;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &::-moz-selection {
                color: white;
                background: $color_0091B3;
            }

            &::selection {
                color: white;
                background: $color_0091B3;
            }
        }
    }

    .reasons-container {
        max-height: 250px;
        width: 100%;
        overflow: auto;
        padding: 15px 17px 0px 17px;
        box-sizing: border-box;

        ul {
            padding-top: 0px !important;
            padding-bottom: 10px !important;
        }

        li {
            list-style: initial;
            padding-left: 0;
            margin-left: 18px;
            margin-bottom: 8px;
            color: #071822;
            font-size: $font14;
            line-height: 17px;
            font-weight: $regularFont;
        }

        li::marker {
            font-size: $font12;
        }
    }

    .copy-paste-container {
        font-size: 12px;
        padding: 9px 17px;

        hr {
            background-color: $color_text;
            margin-bottom: 10px;
        }

        .flex .flex-1 {
            align-items: center;
        }

        div {
            p {
                font-size: $font12;
                line-height: 14.4px;
                font-weight: $regularFont;
                color: #071822 !important;

                span {
                    font-weight: $boldFont;
                }

            }
            &.copy {
                padding-left: 5px;
                color: $color_0091B3 !important;
                text-decoration: underline;
            }
        }
    }
}

.otp {
    .secondary-btn {
        color: #00797C;
        border-color: #00797C;
    }

    .form-group-field {
        input[type="submit"] {
            background-color: #00797C;
        }
    }
}

#moreActionMenu {
    .MuiPaper-rounded {
        border-radius: 0;
        box-shadow: none;
    }

    .MuiMenu-list {
        padding-top: 0;
        padding-bottom: 0;
        min-width: 215px;
        background: $color_green !important;
        margin-top: 5px;

        li {
            font-family: 'Roboto';
            font-size: $font14 !important;
            padding: 8px 16px !important;
            line-height: 16px !important;
            font-weight: 400 !important;
            min-height: auto !important;
            justify-content: flex-start;

            a {
                font-family: 'Roboto';
                font-size: $font14 !important;
                line-height: 16px !important;
                font-weight: 400 !important;
                min-height: auto !important;
                color: $color_white;

                &:hover {
                    font-weight: 500 !important;
                }
            }

            &:hover {
                font-weight: 500 !important;
            }

            &:nth-child(even) {
                background: rgba(255, 255, 255, 0.4) !important;
            }
        }
    }
}